<template>
  <div class="footer-box">
    <div class="left">
      Powered by 广州元维创想有限公司
    </div>
    <div class="right">
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023003606号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBox",
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  watch:{
    $route(to){
      console.log(to.path);
      if(to.path === '/work_more'){
        this.isRelative = true
      }else{
        this.isRelative = false
      }
    }
  },
  data() {
    return{
      isRelative: false
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.footer-box{
  position: fixed;
  z-index: 10;
  bottom: 0;
  height: 80px;
  line-height: 80px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  .left{
    margin-left: 40px;
    width:192px;
    height:16px;
    color:#ffffff;
    font-size:12px;
    text-align:center;
  }
  .right{
    margin-right: 40px;
    width:130px;
    height:16px;
    color:#ffffff;
    font-size:12px;
    text-align:center;
    >a{
      color:#ffffff;
    }
  }
}
</style>