<template>
  <div id="app">
    <NavMenu :activeIndex="$store.state.activeIndex" @changeMenu="changeMenu"/>
    <!-- <SwiperContainer :index="activeIndex" @changeIndex="changeSideIndex"/> -->
    <router-view></router-view>
    <FooterBox v-if="!isRelative"/>
  </div>
</template>

<script>
import NavMenu from "./components/NavMenu.vue";
//import SwiperContainer from "./components/SwiperContainer.vue";
import FooterBox from "./components/FooterBox.vue";

export default {
  name: "App",
  components: {
    NavMenu,
    //SwiperContainer,
    FooterBox,
  },
  data() {
    return {
      activeIndex: 0,
      isRelative: false
    }
  },
  watch:{
    $route(to){
      console.log(to.path);
      if(to.path === '/work_more'){
        this.isRelative = true
      }else{
        this.isRelative = false
      }
    }
  },
  methods: {
    changeSideIndex(index) {
      //this.activeIndex = index
      this.$store.commit('changeActiveIndex', index)
    },
    changeMenu(index) {
      //this.activeIndex = index
      this.$store.commit('changeActiveIndex', index)
    },
  }
};
</script>

<style>
#app {
  min-width: 1200px;
  height: 100vh;
  /*overflow: hidden;*/
}

.video-js .vjs-big-play-button{
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  width: 58px !important;
  height: 58px !important;
  background-color: transparent !important;
  background: url('./assets/image/work/btn_play.png') no-repeat center center !important;
  background-size: 100% 100%;
  cursor: pointer;
  border: none !important;
        
}
.video-js .vjs-big-play-button:hover{
  transition: all .3s;
  transform: scale(1.1);
}
</style>
