<template>
  <div class="header">
    <div class="left">
      <img class="logo" :src="logo" alt="">
    </div>
    <div class="right">
      <ul class="menu">
        <li class="menu-item" v-for="(item, index) in menuList" :key="index" @click="clickMenu(index)">
          <div class="menu-item_name">{{item.name}}</div>
          <span class="menu-item_bar" :class="{'active': index === activeIndex}"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/image/common/logo.png'
export default {
  name: "NavMenu",
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return{
      logo: Logo,
      menuList: [
        {
          name: '首页',
        },
        {
          name: '课程',
        },
        {
          name: '作品',
        },
        {
          name: '研发',
        },
        {
          name: '服务',
        },
        {
          name: '业务',
        },
        {
          name: '关于',
        },
        {
          name: '团队',
        }
      ]
    }
  },
  methods: {
    clickMenu(index) {
      this.$router.push('/')
      this.$emit('changeMenu', index)
    }
  }
}
</script>

<style scoped lang="scss">
.header{
  position: absolute;
  z-index: 10;
  top: 0;
  height: 80px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  .left{
    margin-left: 40px;
    .logo{
      width: 215px;
      height: 80px;
    }
  }
  .right{
    margin-right: 40px;
    .menu{
      display: flex;
      .menu-item{
        width: 61px;
        height: 80px;
        position: relative;
        cursor: pointer;
        user-select: none;
        &_name{
          color: #fff;
          text-align: center;
          height: 80px;
          line-height: 80px;
        }
        &_bar{
          position: absolute;
          bottom: 5px;
          left: 10px;
          display: inline-block;
          width:41px;
          height:5px;
          border-radius:10px 10px 0px 0px;
          &.active{
            background:#ff5e2c;
          }
        }
      }
    }
  }
}
</style>