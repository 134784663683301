import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

import './assets/css/.work.css'

import {
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Row,
  Col
} from 'element-ui';
Vue.use(VueAwesomeSwiper)
Vue.use(VideoPlayer)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Row)
Vue.use(Col)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
